export default {
  data() {
    return {
      baseType: {
        subject: ['专题详情','专题'],
        course: ['课程详情','课程'],
        case: ['精选案例','案例'],
        schedule: ['日程安排','日程'],
        chairman: ['联席主席','主席'],
        producer: ['出品人','出品人'],
        invite: ['邀请/宣传图', '基本设置']
      }, // 类型与描述(label文字)
      noCardType: ['invite'], // 不需要卡片模板的特殊类型
      secondaryKeys: { subject: 'slid', course: 'clid' }, // 专题/课程 对应的二级id的key
      typesAllIn: [ 'subject', 'course', 'chairman', 'producer' ], // 可批量生成的类型
      // selectParams: { year: false, sid: false, type: false, cardId: false, cardDetailId: false, venueId: false, courseId: false, scheduleId: false, lecturerId: false },
      // paramsAllIn: { sid: false, type: false, cardid: false, id: [], year: false },
    }
  },
}